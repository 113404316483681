export default {
  PRODUCT_REPEATER_CLASS: 'is-open',

  FACET_TITLE_ICON: `
  <i rt-if="!this.isCollapsed" class="icon minus" aria-hidden="true"><svg><use xlinkHref="#icon-delete"></use></svg></i>
  <i rt-if="this.isCollapsed" class="icon plus" aria-hidden="true"><svg><use xlinkHref="#icon-add"></use></svg></i>
  `,
  FACET_DIALOG_DONE: 'Apply Filters',
  FILTERS_ICON: `
  <rt-virtual rt-if="this.withSelection" template="icons/filter-filled" />
  <rt-virtual rt-if="!this.withSelection" template="icons/filter-empty" />`,

  GARAGE_SIZE: '<span class="garage-size" key="garage-size">( {{size}} )</span>',

  CURRENT_SEARCH: 'Active filters:',
  START_OVER: 'Clear Filters',

  PRICE_FACET_GO_TEXT: 'Apply',
  GO_TEXT: 'Next',
};

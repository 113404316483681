
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchPageRT () {
    return _createElement('div', { 'className': 'clear-self cm_SearchPage' }, [this.FacetPanel(function () {
            return _createElement('div', { 'className': 'cm_FacetPanel' }, [[this.filterChips(function () {
                        function repeatChips1(chips, chipsIndex) {
                            return [chips(function () {
                                    function repeatI1_reviewChip(i, iIndex) {
                                        return _createElement('span', {
                                            'key': `${ this.field }|${ i }`,
                                            'className': 'cm_star cm_star__' + (!(this.value - i) || this.value - i < 0.25 ? 'empty' : this.value - i < 0.75 ? 'half' : 'full')
                                        });
                                    }
                                    function scopeInchMm1_rangeChip() {
                                        var inch = [].includes(this.field) ? '"' : '';
                                        var mm = [
                                            'wheel_bore',
                                            'wheel_offset'
                                        ].includes(this.field) ? 'mm' : '';
                                        return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '365' }, this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm) : null, this.toPosInf ? _createElement(_Fragment, { 'key': '491' }, this.selectedRange[0], inch, mm, ' and more') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '591' }, this.selectedRange[1], inch, mm, ' and less') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                                'className': 'cm_icon cm_icon-times',
                                                'height': '20px',
                                                'role': 'img',
                                                'viewBox': '0 0 22 22',
                                                'key': '7210'
                                            }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                                    }
                                    return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '0 0 22 22',
                                            'key': '2570'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'reviewChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_reviewChip' }, _createElement.apply(this, [
                                        'a',
                                        {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        },
                                        _createElement('span', { 'className': 'cm_chip-name' }, this.name, ': '),
                                        _map([
                                            0,
                                            1,
                                            2,
                                            3,
                                            4
                                        ], repeatI1_reviewChip.bind(this)),
                                        this.value !== '5' ? _createElement('span', { 'key': '391' }, ' and up') : null
                                    ]), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '0 0 22 22',
                                            'key': '4900'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'rangeChip' ? scopeInchMm1_rangeChip.apply(this, []) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                            'className': 'facettitle',
                            'tabIndex': '0'
                        }, 'Active filters:'), _createElement('div', { 'className': 'facetbody' }, _createElement.apply(this, [
                            'div',
                            {
                                'role': 'list',
                                'className': 'cmRepeater_chips'
                            },
                            _map(this.chips, repeatChips1.bind(this))
                        ]), _createElement('div', {
                            'className': 'cm_btn  cm_filter-chips_button',
                            'data-cm-role': 'start-over'
                        }, '\n        Clear Filters\n      '))));
                    }, {
                        widgetName: 'filter-chips',
                        items: undefined
                    })]], [this.facets(function () {
                    function repeatFacets1(facets, facetsIndex) {
                        return [facets(function () {
                                function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                    return [ShowAlwaysValues(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues2(Values, ValuesIndex) {
                                    return [Values(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues3(Values, ValuesIndex) {
                                    return [Values(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), !this.isCustomRange ? [
                                                _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem',
                                                    'key': '1541'
                                                }, _createElement('span', {}, this.value)),
                                                _createElement('div', {
                                                    'className': 'facetentryval',
                                                    'key': '1543'
                                                }, this.hitCount)
                                            ] : null, this.isCustomRange ? [_createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem',
                                                    'key': '3901'
                                                }, this.finite ? _createElement('span', { 'key': '503' }, this.range[0], ' - ', this.range[1]) : null, this.toPosInf ? _createElement('span', { 'key': '580' }, this.range[0], ' and more') : null, this.toNegInf ? _createElement('span', { 'key': '650' }, this.range[1], ' and less') : null)] : null);
                                        }, { count: undefined })];
                                }
                                function scopeInchMm4() {
                                    var inch = [].includes(this.field) ? '"' : '';
                                    var mm = [
                                        'wheel_bore',
                                        'wheel_offset'
                                    ].includes(this.field) ? 'mm' : '';
                                    return _createElement('div', {
                                        'className': 'cmTemplate_sliderFacet',
                                        'key': '12250'
                                    }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, '\n    Selected: ', this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm, '\n  '), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min, inch, mm), _createElement('div', { 'className': 'max' }, this.max, inch, mm), _createElement('div', { 'className': 'clear' }))), [this.Inputs(function () {
                                            return _createElement('div', { 'className': 'input cm_Inputs' }, this.inputMin ? [[this.inputMin(function () {
                                                        return _createElement('div', {
                                                            'placeholder': 'Min',
                                                            'className': 'cm_inputMin'
                                                        });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })]] : null, this.inputMin && this.inputMax ? _createElement('span', {
                                                'className': 'separator',
                                                'key': '334'
                                            }, '\u2014') : null, this.inputMax ? [[this.inputMax(function () {
                                                        return _createElement('div', {
                                                            'placeholder': 'Max',
                                                            'className': 'cm_inputMax'
                                                        });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })]] : null, _createElement('button', {
                                                'type': 'button',
                                                'className': 'cm_btn',
                                                'onClick': this.setCustomRange
                                            }, '\n    Apply\n  '));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]);
                                }
                                function repeatShowAlwaysValues5(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                    return [ShowAlwaysValues(function () {
                                            function scopeStarFill1(i, iIndex) {
                                                var starFill = this.value - i;
                                                return _createElement('span', {
                                                    'key': i,
                                                    'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                });
                                            }
                                            function repeatI2(i, iIndex, starFill) {
                                                return scopeStarFill1.apply(this, [
                                                    i,
                                                    iIndex
                                                ]);
                                            }
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, _createElement.apply(this, [
                                                'span',
                                                { 'className': 'cm_review-stars' },
                                                _map([
                                                    0,
                                                    1,
                                                    2,
                                                    3,
                                                    4
                                                ], repeatI2.bind(this))
                                            ]), this.value !== '5' ? _createElement('span', {
                                                'className': 'cm_and-up',
                                                'key': '499'
                                            }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues6(Values, ValuesIndex) {
                                    return [Values(function () {
                                            function scopeStarFill1(i, iIndex) {
                                                var starFill = this.value - i;
                                                return _createElement('span', {
                                                    'key': i,
                                                    'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                });
                                            }
                                            function repeatI2(i, iIndex, starFill) {
                                                return scopeStarFill1.apply(this, [
                                                    i,
                                                    iIndex
                                                ]);
                                            }
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, _createElement.apply(this, [
                                                'span',
                                                { 'className': 'cm_review-stars' },
                                                _map([
                                                    0,
                                                    1,
                                                    2,
                                                    3,
                                                    4
                                                ], repeatI2.bind(this))
                                            ]), this.value !== '5' ? _createElement('span', {
                                                'className': 'cm_and-up',
                                                'key': '499'
                                            }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                return _createElement('div', { 'className': 'facetholder cm_field-' + this.field }, this.template !== 'toggleFacet' ? [
                                    _createElement('div', {
                                        'className': 'facettitle',
                                        'data-cm-role': 'toggle-facet',
                                        'tabIndex': '0',
                                        'key': '561'
                                    }, _createElement('span', {}, this.name), !this.isCollapsed ? _createElement('i', {
                                        'className': 'icon minus',
                                        'aria-hidden': 'true',
                                        'key': '236'
                                    }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-delete' }))) : null, this.isCollapsed ? _createElement('i', {
                                        'className': 'icon plus',
                                        'aria-hidden': 'true',
                                        'key': '352'
                                    }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-add' }))) : null),
                                    _createElement('div', {
                                        'className': 'facetbody' + (this.template === 'simpleFacet' && this.needShowMore && !this.moreValuesToShow ? ' expanded' : ''),
                                        'key': '563'
                                    }, this.template === 'simpleFacet' ? _createElement('div', {
                                        'role': 'list',
                                        'className': 'cmTemplate_simpleFacet',
                                        'key': '660'
                                    }, this.showFilterInput ? _createElement('div', {
                                        'className': 'filter-input',
                                        'key': '758'
                                    }, [this.filterInput(function () {
                                            return _createElement('div', {
                                                'className': 'input  cm_filterInput',
                                                'placeholder': 'Enter'
                                            });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], this.inputNotEmpty ? _createElement('span', {
                                        'className': 'filter-input_clear-container',
                                        'onClick': this.clearInput,
                                        'key': '1078'
                                    }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                            'className': 'facetdiv',
                                            'key': '12471'
                                        }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_ShowAlwaysValues' },
                                        _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                                    ]), _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_Values' },
                                        _map(this.Values, repeatValues2.bind(this))
                                    ]), this.needShowMore ? _createElement('div', {
                                        'className': 'facetdiv cm_show-all-container',
                                        'key': '3078'
                                    }, _createElement('a', {
                                        'className': 'cm_show-all',
                                        'data-cm-role': 'toggle-show-more',
                                        'tabIndex': '0'
                                    }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null, this.template === 'selectFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_selectFacet',
                                        'key': '3406'
                                    }, [this.select(function () {
                                            function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                            }
                                            function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                                            }
                                            function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                                                var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                                                var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                                                var isPopularField = popularEntries.length && unpopularEntries.length;
                                                return [
                                                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                                        'value': '',
                                                        'key': 'null-option'
                                                    }, '\n          ', this.title, '\n        ') : null,
                                                    this.loading ? _createElement('option', {
                                                        'key': 'loading-option',
                                                        'disabled': true
                                                    }, '...loading...') : null,
                                                    isPopularField ? [
                                                        _createElement('option', {
                                                            'className': 'cm_option_title',
                                                            'disabled': true,
                                                            'key': '11921'
                                                        }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                                                        _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                                                        _createElement('option', {
                                                            'className': 'cm_option_title',
                                                            'disabled': true,
                                                            'key': '11924'
                                                        }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                                                    ] : null,
                                                    _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                                                ];
                                            }
                                            function scopeShowSelectedValues4() {
                                                var showSelectedValues = this.selectedEntries.length;
                                                return _createElement('option', {
                                                    'key': '_current',
                                                    'value': '_current'
                                                }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [this.title] : null);
                                            }
                                            function repeatEntry5(entry, index) {
                                                return _createElement('div', {
                                                    'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                                    'key': entry.value,
                                                    'onClick': () => this.onChange(entry.term)
                                                }, this.showCheckboxes ? _createElement('input', {
                                                    'type': 'checkbox',
                                                    'readOnly': true,
                                                    'checked': entry.selected,
                                                    'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                                    'key': '3516'
                                                }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                                    'className': 'cm_dropdown_hitCount',
                                                    'key': '3718'
                                                }, entry.hitCount) : null);
                                            }
                                            return _createElement('div', { 'className': 'cm_select' }, _createElement('div', { 'className': this.extraClassName + (this.disabled ? ' cm_disabled' : '') }, !this.isSearchable ? _createElement('div', {
                                                'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                                'key': '177'
                                            }, _createElement('select', {
                                                'className': 'cm_select_inner-select cm_select__pretty',
                                                'onChange': this.onChange,
                                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                                'disabled': this.disabled,
                                                'aria-label': this.title
                                            }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                                        return _createElement('div', { 'className': 'cm_filterInput' });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                                'div',
                                                {
                                                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                                    'aria-label': this.title,
                                                    'key': '2826'
                                                },
                                                !this.hideNullOption ? _createElement('div', {
                                                    'className': 'option',
                                                    'key': 'null-option',
                                                    'onClick': () => this.onChange('')
                                                }, '\n      ', this.title, '\n    ') : null,
                                                this.loading ? _createElement('div', {
                                                    'className': 'option',
                                                    'key': 'null-option',
                                                    'disabled': true
                                                }, '...loading...') : null,
                                                !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                                            ]) : null));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]) : null, this.template === 'priceFacet' ? _createElement('div', {
                                        'role': 'list',
                                        'className': 'cmTemplate_priceFacet',
                                        'key': '9538'
                                    }, _createElement('div', { 'className': 'cm_flex' }, _createElement('div', { 'className': 'cm_flex-grow' }, 'MIN.'), _createElement('div', {}, 'MAX.')), [this.Inputs(function () {
                                            return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                                    return _createElement('div', {
                                                        'placeholder': 'Min price',
                                                        'className': 'cm_inputMin'
                                                    });
                                                }, {
                                                    widgetName: 'undefined',
                                                    items: undefined
                                                })], _createElement('span', { 'className': 'separator' }, '\u2014'), [this.inputMax(function () {
                                                    return _createElement('div', {
                                                        'placeholder': 'Max price',
                                                        'className': 'cm_inputMax'
                                                    });
                                                }, {
                                                    widgetName: 'undefined',
                                                    items: undefined
                                                })]);
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], !this.ranges.length ? [this.slider] : null, this.ranges.length ? [_createElement.apply(this, [
                                            'div',
                                            {
                                                'className': 'cmRepeater_Values',
                                                'key': '105571'
                                            },
                                            _map(this.Values, repeatValues3.bind(this))
                                        ])] : null, _createElement('button', {
                                        'type': 'button',
                                        'className': 'cm_btn',
                                        'onClick': this.setCustomRange
                                    }, '\n  Apply\n')) : null, this.template === 'sliderFacet' ? scopeInchMm4.apply(this, []) : null, this.template === 'reviewFacet' ? _createElement('div', {
                                        'role': 'list',
                                        'className': 'cmTemplate_reviewFacet',
                                        'key': '13991'
                                    }, _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_ShowAlwaysValues' },
                                        _map(this.ShowAlwaysValues, repeatShowAlwaysValues5.bind(this))
                                    ]), _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_Values' },
                                        _map(this.Values, repeatValues6.bind(this))
                                    ])) : null)
                                ] : null, this.template === 'toggleFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_toggleFacet',
                                    'key': '17151'
                                }, _createElement('div', { 'className': 'facettitle cm_facet-toggle' }, _createElement('span', {}, this.name), _createElement('label', { 'className': 'cm_facet-toggle_switch' }, _createElement('input', {
                                    'className': 'cm_facet-toggle_input',
                                    'type': 'checkbox',
                                    'checked': this.isToggled,
                                    'onClick': this.toggleFacet
                                }), _createElement('span', { 'className': 'cm_facet-toggle_slider' })))) : null);
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_facets' },
                        _map(this.facets, repeatFacets1.bind(this))
                    ]));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], _createElement('div', { 'className': 'cm_main-content' }, _createElement('div', { 'id': 'itemsBlock' }, [this.message(function () {
            return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired.'), '\n  Product information, including pricing and fitments, is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                    'href': 'mailto:support@convermax.com',
                    'className': 'cm_message-link'
                }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('AggressiveWheels') ? [_createElement('div', { 'key': '4980' }, '\n  Warning: Aggressive wheel options are displayed, but we cannot guarantee they will fit your selected\n  vehicle.\n')] : null, this.hasMessage('IncompatibleParts') && !this.notFilteredByVehicle ? [_createElement('div', {
                    'className': 'cm_message__incompatible',
                    'key': '7050'
                }, _createElement('div', {}, '\n    The products shown below will not fit a ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    The products results are based on your keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected && !this.notFilteredByVehicle && !this.hasMessage('NonVehicleProducts') ? [_createElement('div', {
                    'className': 'cm_message__universal',
                    'key': '10350'
                }, '\n  The system was unable to find any vehicle specific products that fit a\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Click ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'href': this.searchByVehicleUrl
                }, 'here'), ' to clear your vehicle\n  selection and browse all products in the category.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                'className': 'cmTemplate_badRequest',
                'key': '1568'
            }, 'Your request failed to process (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.startOver
            }, 'clear your search'), ' and try\nsearching for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                'className': 'cmTemplate_corrected',
                'key': '1982'
            }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any products. The system searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                'className': 'cmTemplate_networkError',
                'key': '2236'
            }, 'Network error\n', this.error ? [
                '(id: ',
                this.error.userId,
                ', timestamp: ',
                this.error.timestamp,
                ', instance: ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nThe search server could not be reached. Please try again later.\n') : null, this.template === 'nothing' ? _createElement('div', {
                'className': 'cmTemplate_nothing',
                'key': '2554'
            }, _createElement('div', {}, this.pageType !== 'search' && (!this.isFacetsSelected || this.notFilteredByVehicle) ? [
                '\n    This ',
                this.pageType,
                ' doesn\'t contain any\n    ',
                !!this.vehicleString && !this.notFilteredByVehicle ? [
                    '\n      products that fit a ',
                    _createElement('span', { 'key': '28171' }, '\'', this.vehicleString, '\'.')
                ] : null,
                !this.vehicleString || this.notFilteredByVehicle ? [' products.'] : null
            ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '30991' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString && !this.notFilteredByVehicle ? [
                '\n    Click ',
                _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'onClick': this.startOver,
                    'key': '32301'
                }, 'here'),
                ' to clear\n    ',
                !this.vehicleString ? [' selection. '] : null,
                this.vehicleString && (!this.isFacetsSelected || !this.notFilteredByVehicle) ? ['\n      all keyword searches, filter selections, and vehicle selections to again browse all products.\n    '] : null
            ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                'className': 'cmTemplate_timeout',
                'key': '3749'
            }, 'Your search request timed out.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                'className': 'cmTemplate_partial',
                'key': '3968'
            }, 'The system was unable to find products to match your entire search of\n', _createElement('span', { 'className': 'query' }, this.originalQuery), '. A partial search was performed instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                'className': 'cmTemplate_unknown',
                'key': '4212'
            }, 'Your search request failed to process\n', this.error ? [
                '\n  (',
                this.error.status ? [
                    _createElement('b', { 'key': '43630' }, 'status:'),
                    ' ',
                    this.error.status,
                    ', '
                ] : null,
                this.error.userId ? [
                    _createElement('b', { 'key': '44500' }, 'id:'),
                    ' ',
                    this.error.userId,
                    ', '
                ] : null,
                this.error.timestamp ? [
                    _createElement('b', { 'key': '45330' }, 'timestamp:'),
                    ' ',
                    this.error.timestamp,
                    ', '
                ] : null,
                _createElement('b', { 'key': '43284' }, 'instance:'),
                ' ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.customMessage(function () {
            return _createElement('div', { 'className': 'cm_message cm_customMessage' }, []);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchVehicleWidget(function () {
            function repeatSelects1(selects, selectsIndex) {
                return [selects(function () {
                        function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                        }
                        function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                            var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                            var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                            var isPopularField = popularEntries.length && unpopularEntries.length;
                            return [
                                !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                    'value': '',
                                    'key': 'null-option'
                                }, '\n          ', this.title, '\n        ') : null,
                                this.loading ? _createElement('option', {
                                    'key': 'loading-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                isPopularField ? [
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '11691'
                                    }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                                    _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '11694'
                                    }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                                ] : null,
                                _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                            ];
                        }
                        function scopeShowSelectedValues4() {
                            var showSelectedValues = this.selectedEntries.length;
                            return _createElement('option', {
                                'key': '_current',
                                'value': '_current'
                            }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [this.title] : null);
                        }
                        function repeatEntry5(entry, index) {
                            return _createElement('div', {
                                'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                'key': entry.value,
                                'onClick': () => this.onChange(entry.term)
                            }, this.showCheckboxes ? _createElement('input', {
                                'type': 'checkbox',
                                'readOnly': true,
                                'checked': entry.selected,
                                'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                'key': '3493'
                            }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                'className': 'cm_dropdown_hitCount',
                                'key': '3695'
                            }, entry.hitCount) : null);
                        }
                        return _createElement('div', { 'className': this.extraClassName + (this.disabled ? ' cm_disabled' : '') }, !this.isSearchable ? _createElement('div', {
                            'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                            'key': '154'
                        }, _createElement('select', {
                            'className': 'cm_select_inner-select cm_select__pretty',
                            'onChange': this.onChange,
                            'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                            'disabled': this.disabled,
                            'aria-label': this.title
                        }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                    return _createElement('div', { 'className': 'cm_filterInput' });
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                            'div',
                            {
                                'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                'aria-label': this.title,
                                'key': '2803'
                            },
                            !this.hideNullOption ? _createElement('div', {
                                'className': 'option',
                                'key': 'null-option',
                                'onClick': () => this.onChange('')
                            }, '\n      ', this.title, '\n    ') : null,
                            this.loading ? _createElement('div', {
                                'className': 'option',
                                'key': 'null-option',
                                'disabled': true
                            }, '...loading...') : null,
                            !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                        ]) : null);
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__search cm_SearchVehicleWidget' }, this.template === 'active' ? _createElement('div', {
                'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
                'key': '80'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, window.Convermax.config?.selectYourVehicleTitle || 'Select Your Vehicle'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                'div',
                { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                _map(this.selects, repeatSelects1.bind(this))
            ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go',
                'onClick': this.selectVehicle,
                'disabled': !this.allowToSetVehicle,
                'role': 'button',
                'tabIndex': this.allowToSetVehicle ? '0' : '-1'
            }, '\n    Next\n  '), _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear',
                'onClick': this.discardVehicle,
                'disabled': !this.allowToDiscardVehicle,
                'role': 'button',
                'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
            }, '\n    Clear\n  ')))) : null, this.template === 'locked' ? _createElement('div', {
                'className': 'cm_vehicle-widget_label cmTemplate_locked',
                'key': '6937'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, '\n  ', this.vehicleString, '\n  ', _createElement('span', { 'className': 'cm_vehicle-widget_subtitle' }, this.fitmentSearchTitle), [this.facetToggle(function () {
                    return _createElement('div', { 'className': 'cm_facetToggle' }, _createElement('span', {
                        'className': 'cm_facet-toggle_label',
                        'onClick': this.toggleFacet
                    }, '\n  Show Universal Parts\n'), _createElement('label', { 'className': 'cm_facet-toggle_switch' }, _createElement('input', {
                        'className': 'cm_facet-toggle_input',
                        'type': 'checkbox',
                        'checked': this.isToggled,
                        'onChange': this.toggleFacet
                    }), _createElement('span', { 'className': 'cm_facet-toggle_slider' })));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button',
                'onClick': this.changeVehicle,
                'role': 'button',
                'tabIndex': '0'
            }, '\n      Change Vehicle\n    '), _createElement('div', {
                'className': 'cm_vehicle-widget_button',
                'onClick': this.discardVehicle,
                'role': 'button',
                'tabIndex': '0'
            }, '\n      Clear Selection\n    ')))) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], _createElement('div', { 'className': 'cm_disclaimer' }, _createElement('span', {}, 'Disclaimer:'), ' our vehicle fit guide is a general tool for assessing speaker compatibility and\n    may not precisely match your vehicle\'s specifications. For accurate fitment and tailored advice, please\n    call our sales team at ', _createElement('a', { 'href': 'tel:+15105279888' }, '510-527-9888.')), [this.VehicleSpeakerFacetPanel(function () {
            return _createElement('div', { 'className': 'cm_vehicle-speakers cm_VehicleSpeakerFacetPanel' }, [this.facets(function () {
                    function repeatFacets1(facets, facetsIndex) {
                        return [facets(function () {
                                function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                    return [ShowAlwaysValues(function () {
                                            function onClick1(e) {
                                                e.stopPropagation();
                                            }
                                            return _createElement('div', { 'className': 'fitment-cell' }, _createElement('div', { 'className': 'cm_vehicle-speakers-facettitle fitment-title cm_vertical-title' }, _createElement('span', { 'className': 'fitment-value' }, 'Location'), _createElement('span', { 'className': 'fitment-value' }, 'Size')), _createElement('div', { 'className': 'fitment-values' }, _createElement('span', {
                                                'onClick': onClick1.bind(this),
                                                'className': 'fitment-value fitment-field-' + this.value.toLowerCase().replaceAll(' ', '-'),
                                                'key': 'location-' + this.value
                                            }, '\n                  ', this.value.split(' ').slice(0, -1).join(' '), '\n                '), _createElement('span', {
                                                'className': 'fitment-value fitment-field-' + this.value.toLowerCase().replaceAll(' ', '-') + (this.isSelected ? ' cm_selected' : ''),
                                                'key': 'size-' + this.value
                                            }, '\n                  ', this.value.split(' ')[this.value.split(' ').length - 1], '\n                ')));
                                        }, { count: undefined })];
                                }
                                return _createElement('div', { 'className': 'cm_field-' + this.field }, _createElement('div', { 'className': 'cm_vehicle-speakers-title-container' }, _createElement('span', { 'className': 'cm_vehicle-speakers-title' }, this.name), _createElement('span', { 'className': 'cm_vehicle-speakers-subtitle' }, ' Speaker Size and Location ')), this.template === 'simpleFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_simpleFacet',
                                    'key': '269'
                                }, _createElement('div', { 'className': 'cm_vehicle-speakers-facetbody' }, _createElement('div', { 'className': 'cm_vehicle-speakers-facettitle cm_horizontal-title' }, _createElement('div', { 'className': 'fitment-cell' }, _createElement('span', { 'className': 'fitment-value' }, 'Location')), _createElement('div', { 'className': 'fitment-cell' }, _createElement('span', { 'className': 'fitment-value' }, 'Size'))), _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cm_vehicle-speakers-values-container cmRepeater_ShowAlwaysValues' },
                                    _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                                ]))) : null);
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_facets' },
                        _map(this.facets, repeatFacets1.bind(this))
                    ]));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'product-listing-filter-section cm_SearchHeader' }, _createElement('div', { 'className': 'cm_SearchHeader__container' }, _createElement('div', { 'className': 'cm_left-block' }, _createElement('div', { 'className': 'cm_total-hits' }, _createElement('div', { 'className': 'product-listing-counter show' }, _createElement('div', { 'className': 'pagination-count' }, this.pageFrom, ' of ', this.pageTo, ' Items'))), [this.pagination(function () {
                    function repeatButtons1(buttons, buttonsIndex) {
                        return [buttons(function () {
                                return this.template === 'prev' ? _createElement('li', { 'className': 'pagination-item pagination-item--previous cmTemplate_prev' }, _createElement('a', {
                                    'href': this.pageLink,
                                    'className': 'pagination-link',
                                    'aria-label': 'link'
                                }, _createElement('i', {
                                    'className': 'icon',
                                    'aria-hidden': 'true'
                                }, _createElement('svg', {}, _createElement('use', {
                                    'xmlnsXlink': 'http://www.w3.org/1999/xlink',
                                    'xlinkHref': '#icon-chevron-left'
                                }))), '\n          Previous\n        ')) : this.template === 'next' ? _createElement('li', { 'className': 'pagination-item pagination-item--next cmTemplate_next' }, _createElement('a', {
                                    'href': this.pageLink,
                                    'className': 'pagination-link',
                                    'aria-label': 'link'
                                }, '\n          Next\n          ', _createElement('i', {
                                    'className': 'icon',
                                    'aria-hidden': 'true'
                                }, _createElement('svg', {}, _createElement('use', {
                                    'xmlnsXlink': 'http://www.w3.org/1999/xlink',
                                    'xlinkHref': '#icon-chevron-right'
                                }))))) : this.template === 'delimiter' ? _createElement('li', { 'className': 'pagination-item cmTemplate_delimiter' }, _createElement('span', { 'className': 'pagination-link' }, '\u2026')) : this.template === 'page' ? _createElement('li', { 'className': 'pagination-item' + (this.isActive ? ' pagination-item--current' : '') + ' cmTemplate_page' }, this.isActive ? _createElement('a', {
                                    'href': this.pageLink,
                                    'className': 'pagination-link',
                                    'key': '122'
                                }, this.page) : null, !this.isActive ? _createElement('a', {
                                    'href': this.pageLink,
                                    'className': 'pagination-link',
                                    'key': '218'
                                }, this.page) : null) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_pagination' }, _createElement('div', { 'className': 'product-listing-pagination show' }, _createElement('nav', { 'className': 'pagination' }, _createElement.apply(this, [
                        'ul',
                        { 'className': 'pagination pagination-list cmRepeater_buttons' },
                        _map(this.buttons, repeatButtons1.bind(this))
                    ]))));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]), _createElement('div', { 'className': 'cm_right-block' }, [this.facetDialogButton(function () {
                    return _createElement('div', { 'className': 'cm_facetDialogButton' }, [this.dialogButton(function () {
                            return _createElement('div', { 'className': 'cm_dialogButton' }, '\n  Filters \n  ', this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon',
                                    'width': '26',
                                    'height': '27',
                                    'viewBox': '0 0 26 27',
                                    'fill': 'none',
                                    'xmlns': 'http://www.w3.org/2000/svg',
                                    'key': '430'
                                }, _createElement('path', {
                                    'd': 'M24.2501 13.2046H9.43388C9.26634 12.7297 8.99412 12.2987 8.63738 11.9433C7.99408 11.3302 7.13952 10.988 6.2508 10.9878C5.36208 10.9876 4.50736 11.3293 3.86378 11.9422C3.23067 12.5754 2.875 13.4341 2.875 14.3295C2.875 15.2249 3.23067 16.0837 3.86378 16.7168C4.07502 16.9278 4.36142 17.0462 4.65998 17.046C4.95854 17.0458 5.24479 16.9271 5.45576 16.7158C5.66674 16.5046 5.78516 16.2182 5.78497 15.9196C5.78478 15.6211 5.666 15.3348 5.45475 15.1238C5.35018 15.0195 5.26723 14.8956 5.21066 14.7592C5.15409 14.6227 5.12502 14.4764 5.12512 14.3287C5.12521 14.181 5.15447 14.0348 5.21121 13.8985C5.26795 13.7621 5.35106 13.6383 5.45576 13.5341C5.66944 13.3286 5.95442 13.2139 6.25086 13.2142C6.5473 13.2144 6.83212 13.3294 7.0455 13.5352C7.14999 13.6394 7.23288 13.7632 7.28945 13.8995C7.34601 14.0358 7.37512 14.182 7.37512 14.3295C7.37512 14.4771 7.34601 14.6232 7.28945 14.7595C7.23288 14.8958 7.14999 15.0196 7.0455 15.1238C6.83568 15.3351 6.7181 15.6209 6.71851 15.9186C6.71891 16.2164 6.83727 16.5018 7.04767 16.7125C7.25808 16.9232 7.54338 17.0419 7.84113 17.0428C8.13888 17.0436 8.42482 16.9264 8.63636 16.7168C8.99325 16.361 9.26581 15.9297 9.43399 15.4546H24.2501C24.5485 15.4546 24.8346 15.3361 25.0456 15.1251C25.2566 14.9141 25.3751 14.6279 25.3751 14.3296C25.3751 14.0312 25.2566 13.7451 25.0456 13.5341C24.8346 13.3231 24.5485 13.2046 24.2501 13.2046Z',
                                    'fill': '#2F2F2F'
                                }), _createElement('path', {
                                    'd': 'M17.3638 20.9423C17.0069 21.2982 16.7343 21.7295 16.5661 22.2046H1.75C1.45163 22.2046 1.16548 22.3232 0.954505 22.5341C0.743527 22.7451 0.625 23.0313 0.625 23.3296C0.625 23.628 0.743527 23.9141 0.954505 24.1251C1.16548 24.3361 1.45163 24.4546 1.75 24.4546H16.5662C16.7344 24.9297 17.007 25.361 17.3639 25.7169C17.5754 25.9264 17.8614 26.0436 18.1591 26.0428C18.4569 26.042 18.7422 25.9233 18.9526 25.7126C19.163 25.5019 19.2813 25.2164 19.2817 24.9187C19.2821 24.6209 19.1646 24.3351 18.9547 24.1239C18.8502 24.0196 18.7672 23.8956 18.7106 23.7592C18.6541 23.6228 18.625 23.4765 18.6251 23.3288C18.6252 23.1811 18.6545 23.0349 18.7112 22.8985C18.7679 22.7621 18.851 22.6383 18.9558 22.5341C19.1694 22.3287 19.4544 22.214 19.7508 22.2142C20.0473 22.2144 20.3321 22.3295 20.5455 22.5353C20.65 22.6395 20.7329 22.7633 20.7894 22.8996C20.846 23.0359 20.8751 23.182 20.8751 23.3296C20.8751 23.4771 20.846 23.6233 20.7894 23.7596C20.7329 23.8959 20.65 24.0197 20.5455 24.1239C20.3357 24.3351 20.2181 24.6209 20.2185 24.9187C20.2189 25.2164 20.3373 25.5019 20.5477 25.7126C20.7581 25.9233 21.0434 26.042 21.3411 26.0428C21.6389 26.0436 21.9248 25.9264 22.1364 25.7169C22.7693 25.0839 23.1249 24.2255 23.1251 23.3304C23.1253 22.4353 22.7701 21.5767 22.1375 20.9434C21.4942 20.3302 20.6396 19.988 19.7508 19.9878C18.8621 19.9876 18.0074 20.3294 17.3638 20.9423Z',
                                    'fill': '#2F2F2F'
                                }), _createElement('path', {
                                    'd': 'M12.5652 4.20459H1.75C1.45163 4.20459 1.16548 4.32312 0.954505 4.53409C0.743527 4.74507 0.625 5.03122 0.625 5.32959C0.625 5.62796 0.743527 5.91411 0.954505 6.12508C1.16548 6.33606 1.45163 6.45459 1.75 6.45459H12.664C12.5696 5.98952 12.5201 5.50818 12.5201 5.01527C12.5201 4.7412 12.5354 4.4707 12.5652 4.20459Z',
                                    'fill': '#2F2F2F'
                                }), _createElement('circle', {
                                    'cx': '19.7219',
                                    'cy': '5.01533',
                                    'r': '4.8',
                                    'fill': '#6AB783'
                                }))] : null, !this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon empty',
                                    'xmlns': 'http://www.w3.org/2000/svg',
                                    'width': '36',
                                    'height': '36',
                                    'viewBox': '0 0 36 36',
                                    'fill': 'none',
                                    'key': '34040'
                                }, _createElement('path', {
                                    'd': 'M6.75 10.4546H21.5662C21.7344 10.9297 22.007 11.3611 22.3639 11.7169C22.5758 11.9246 22.8611 12.0402 23.1579 12.0386C23.4546 12.037 23.7387 11.9183 23.9483 11.7084C24.158 11.4984 24.2763 11.2141 24.2775 10.9174C24.2787 10.6207 24.1626 10.3355 23.9546 10.1239C23.85 10.0196 23.7671 9.89566 23.7105 9.75922C23.654 9.62278 23.6249 9.47651 23.625 9.32881C23.6251 9.18111 23.6543 9.03488 23.7111 8.89851C23.7678 8.76215 23.8509 8.63832 23.9556 8.53415C24.1693 8.32868 24.4543 8.21401 24.7507 8.21422C25.0472 8.21443 25.332 8.3295 25.5454 8.53528C25.6499 8.63949 25.7328 8.76329 25.7893 8.89959C25.8459 9.03589 25.875 9.18201 25.875 9.32958C25.875 9.47715 25.8459 9.62328 25.7893 9.75958C25.7328 9.89588 25.6499 10.0197 25.5454 10.1239C25.3356 10.3351 25.218 10.6209 25.2184 10.9187C25.2188 11.2164 25.3371 11.5019 25.5476 11.7126C25.7579 11.9233 26.0433 12.042 26.341 12.0428C26.6388 12.0436 26.9247 11.9264 27.1362 11.7169C27.7691 11.0839 28.1248 10.2255 28.125 9.33038C28.1252 8.43526 27.77 7.57668 27.1374 6.9434C26.4941 6.33022 25.6395 5.98806 24.7508 5.98785C23.8621 5.98764 23.0073 6.3294 22.3638 6.94228C22.0069 7.29819 21.7344 7.72955 21.5662 8.20464H6.75C6.45163 8.20464 6.16548 8.32317 5.95451 8.53414C5.74353 8.74512 5.625 9.03127 5.625 9.32964C5.625 9.62801 5.74353 9.91416 5.95451 10.1251C6.16548 10.3361 6.45163 10.4546 6.75 10.4546Z',
                                    'fill': '#2F2F2F'
                                }), _createElement('path', {
                                    'd': 'M29.2501 17.2046H14.4339C14.2663 16.7298 13.9941 16.2988 13.6374 15.9434C12.9941 15.3302 12.1395 14.9881 11.2508 14.9879C10.3621 14.9876 9.50736 15.3294 8.86378 15.9423C8.23067 16.5755 7.875 17.4342 7.875 18.3296C7.875 19.225 8.23067 20.0837 8.86378 20.7169C9.07502 20.9279 9.36142 21.0463 9.65998 21.0461C9.95854 21.0459 10.2448 20.9271 10.4558 20.7159C10.6667 20.5046 10.7852 20.2182 10.785 19.9197C10.7848 19.6211 10.666 19.3349 10.4548 19.1239C10.3502 19.0196 10.2672 18.8957 10.2107 18.7592C10.1541 18.6228 10.125 18.4765 10.1251 18.3288C10.1252 18.1811 10.1545 18.0349 10.2112 17.8985C10.2679 17.7621 10.3511 17.6383 10.4558 17.5342C10.6694 17.3287 10.9544 17.214 11.2509 17.2142C11.5473 17.2144 11.8321 17.3295 12.0455 17.5353C12.15 17.6395 12.2329 17.7633 12.2894 17.8996C12.346 18.0359 12.3751 18.182 12.3751 18.3296C12.3751 18.4772 12.346 18.6233 12.2894 18.7596C12.2329 18.8959 12.15 19.0197 12.0455 19.1239C11.8357 19.3351 11.7181 19.6209 11.7185 19.9187C11.7189 20.2164 11.8373 20.5019 12.0477 20.7126C12.2581 20.9233 12.5434 21.042 12.8411 21.0428C13.1389 21.0436 13.4248 20.9264 13.6364 20.7169C13.9932 20.3611 14.2658 19.9297 14.434 19.4546H29.2501C29.5485 19.4546 29.8346 19.3361 30.0456 19.1251C30.2566 18.9142 30.3751 18.628 30.3751 18.3296C30.3751 18.0313 30.2566 17.7451 30.0456 17.5341C29.8346 17.3232 29.5485 17.2046 29.2501 17.2046Z',
                                    'fill': '#2F2F2F'
                                }), _createElement('path', {
                                    'd': 'M22.3638 24.9423C22.0069 25.2982 21.7343 25.7295 21.5661 26.2046H6.75C6.45163 26.2046 6.16548 26.3232 5.95451 26.5341C5.74353 26.7451 5.625 27.0313 5.625 27.3296C5.625 27.628 5.74353 27.9141 5.95451 28.1251C6.16548 28.3361 6.45163 28.4546 6.75 28.4546H21.5662C21.7344 28.9297 22.007 29.361 22.3639 29.7169C22.5754 29.9264 22.8614 30.0436 23.1591 30.0428C23.4569 30.042 23.7422 29.9233 23.9526 29.7126C24.163 29.5019 24.2813 29.2164 24.2817 28.9187C24.2821 28.6209 24.1646 28.3351 23.9547 28.1239C23.8502 28.0196 23.7672 27.8956 23.7106 27.7592C23.6541 27.6228 23.625 27.4765 23.6251 27.3288C23.6252 27.1811 23.6545 27.0349 23.7112 26.8985C23.7679 26.7621 23.851 26.6383 23.9558 26.5341C24.1694 26.3287 24.4544 26.214 24.7508 26.2142C25.0473 26.2144 25.3321 26.3295 25.5455 26.5353C25.65 26.6395 25.7329 26.7633 25.7894 26.8996C25.846 27.0359 25.8751 27.182 25.8751 27.3296C25.8751 27.4771 25.846 27.6233 25.7894 27.7596C25.7329 27.8959 25.65 28.0197 25.5455 28.1239C25.3357 28.3351 25.2181 28.6209 25.2185 28.9187C25.2189 29.2164 25.3373 29.5019 25.5477 29.7126C25.7581 29.9233 26.0434 30.042 26.3411 30.0428C26.6389 30.0436 26.9248 29.9264 27.1364 29.7169C27.7693 29.0839 28.1249 28.2255 28.1251 27.3304C28.1253 26.4353 27.7701 25.5767 27.1375 24.9434C26.4942 24.3302 25.6396 23.988 24.7508 23.9878C23.8621 23.9876 23.0074 24.3294 22.3638 24.9423Z',
                                    'fill': '#2F2F2F'
                                }))] : null);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('div', { 'className': 'product-listing-filter-right' }, _createElement('div', { 'className': 'product-view-button show ' + this.view }, _createElement('a', {
                'onClick': this.setView('grid'),
                'className': 'product-view-btn grid-view view-button-grid show',
                'data-view': 'product-grid-view'
            }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-grid-view' }))), _createElement('a', {
                'onClick': this.setView('list'),
                'className': 'product-view-btn list-view view-button-list show',
                'data-view': 'product-list-view'
            }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-list-view' }))))), _createElement('div', { 'className': 'cm_sort sort-by' }, _createElement('span', { 'className': 'sort-label' }, 'Sort by: '), '\n      ', this.sortSelect, '\n    '))));
        }, {
            widgetName: 'search-header',
            items: undefined
        })], [this.SearchResult(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        function onClick1() {
                            Convermax.handleProductImageSlider(this.id, 'first');
                        }
                        function onClick2() {
                            Convermax.handleProductImageSlider(this.id, 'second');
                        }
                        function repeatI3(i, iIndex) {
                            return _createElement('span', {
                                'key': i,
                                'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                            });
                        }
                        function mergeProps(inline, external) {
                            var res = Object.assign({}, inline, external);
                            if (inline.hasOwnProperty('style')) {
                                res.style = _.defaults(res.style, inline.style);
                            }
                            if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                res.className = external.className + ' ' + inline.className;
                            }
                            return res;
                        }
                        return _createElement('li', { 'className': 'product' }, _createElement('article', {
                            'data-comp': this.id,
                            'className': 'card',
                            'data-product-id': this.id
                        }, _createElement('figure', { 'className': 'card-figure figure-content' }, _createElement('a', {
                            'href': this.custom_url,
                            'className': 'card-image-link--slider'
                        }, _createElement('div', {
                            'className': 'card-img-container card-img-container--slider',
                            'style': { position: 'relative' }
                        }, this.regular_price > this.price ? _createElement('div', {
                            'className': 'sale-flag-sash',
                            'key': '316'
                        }, _createElement('span', { 'className': 'sale-text' }, 'Sale')) : null, this.preorder ? _createElement('div', {
                            'className': 'stock-label bo-backorder-label',
                            'style': {
                                top: '10%',
                                left: '0px'
                            },
                            'key': '463'
                        }, '\n            Pre-Order\n          ') : null, !this.out_of_stock && !this.preorder ? _createElement('div', {
                            'className': 'stock-label bo-in-stock-label',
                            'style': {
                                top: '10%',
                                left: '0px'
                            },
                            'key': '607'
                        }, '\n            In Stock\n          ') : null, _createElement('img', {
                            'className': 'card-image first is-active',
                            'data-sizes': 'auto',
                            'src': this.imageOrDefault(this.images_image_thumbnail1),
                            'alt': this.removeHTML(this.name),
                            'title': this.removeHTML(this.name),
                            'onError': e => this.onImageError(e, 'images_image_thumbnail1'),
                            'sizes': '240px'
                        }), this.images_image_thumbnail2 ? _createElement('img', {
                            'className': 'card-image second lazyautosizes ls-is-cached lazyloaded',
                            'data-sizes': 'auto',
                            'src': this.imageOrDefault(this.images_image_thumbnail2),
                            'alt': this.removeHTML(this.name),
                            'title': this.removeHTML(this.name),
                            'onError': e => this.onImageError(e, 'images_image_thumbnail2'),
                            'sizes': '240px',
                            'key': '1072'
                        }) : null, _createElement('img', {
                            'className': 'card-image ls-is-cached lazyautosizes lazyloaded',
                            'data-sizes': 'auto',
                            'src': true,
                            'data-src': true,
                            'id': 'swatch-image',
                            'sizes': '240px'
                        }))), this.images_image_thumbnail2 ? _createElement('span', {
                            'onClick': onClick1.bind(this),
                            'className': 'card-image-prev card-image-arrow',
                            'key': '1595'
                        }, _createElement('i', {
                            'className': 'icon',
                            'aria-hidden': 'true'
                        }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-arrow-bg-left' })))) : null, this.images_image_thumbnail2 ? _createElement('span', {
                            'onClick': onClick2.bind(this),
                            'className': 'card-image-next card-image-arrow',
                            'key': '1923'
                        }, _createElement('i', {
                            'className': 'icon',
                            'aria-hidden': 'true'
                        }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-arrow-bg-right' })))) : null, _createElement('figcaption', { 'className': 'card-figcaption' }, _createElement('div', { 'className': 'card-figcaption-body' }, _createElement('button', {
                            'className': 'button button--small card-figcaption-button quickview show',
                            'data-product-id': this.id,
                            'title': 'Quick View'
                        }, _createElement('i', {
                            'className': 'icon',
                            'aria-hidden': 'true'
                        }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-green-view' })))), _createElement('a', {
                            'className': 'button button--small card-figcaption-button wishlist show card_wishlist',
                            'title': 'Add to Wishlist'
                        }, _createElement('p', {
                            'className': 'wishlist_url',
                            'data-whslst_url': '/wishlist.php?action=add&product_id=' + this.id
                        }), _createElement('i', {
                            'className': 'icon',
                            'aria-hidden': 'true'
                        }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-card-icons' })))), _createElement('label', {
                            'className': 'button button--small card-figcaption-button custom-compare show',
                            'htmlFor': 'compare-' + this.id,
                            'title': 'Compare'
                        }, _createElement('input', {
                            'type': 'checkbox',
                            'name': 'products[]',
                            'value': this.id,
                            'id': 'compare-' + this.id,
                            'data-compare-id': this.id
                        }), _createElement('i', {
                            'className': 'icon',
                            'aria-hidden': 'true'
                        }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-unloaded-compare' }))))))), _createElement('div', { 'className': 'card-body card-swatch-body' }, _createElement('p', {
                            'className': 'card-text product-rating',
                            'data-test-info-type': 'productRating'
                        }, [_createElement.apply(this, [
                                'div',
                                {
                                    'className': 'cm_review-stars',
                                    'key': '36590'
                                },
                                _map([
                                    0,
                                    1,
                                    2,
                                    3,
                                    4
                                ], repeatI3.bind(this))
                            ])]), _createElement('h4', { 'className': 'card-title' }, _createElement('a', Object.assign({}, { 'href': this.custom_url }, { dangerouslySetInnerHTML: { __html: this.name } }))), _createElement('div', mergeProps({ 'className': 'productView-description' }, { dangerouslySetInnerHTML: { __html: this.Short_Description } })), _createElement('div', {
                            'className': 'card-text product-price',
                            'data-test-info-type': 'price'
                        }, this.regular_price > this.price ? _createElement('div', {
                            'className': 'price-section price-section--withoutTax rrp-price--withoutTax',
                            'key': '4316'
                        }, _createElement('span', { 'className': 'price price--rrp' }, this.formatPrice(this.regular_price))) : null, _createElement('div', { 'className': 'price-section price-section--withoutTax actual-price' }, _createElement('span', { 'className': 'price price--withoutTax' }, this.formatPrice(this.price)))), _createElement('div', { 'className': 'card-figcaption-button-section' }, !this.out_of_stock && !this.preorder ? _createElement('a', {
                            'href': '/cart.php?action=add&product_id=' + this.id,
                            'data-event-type': 'product-click',
                            'className': 'button button--small card-figcaption-button theme-btn-primary',
                            'key': '4785'
                        }, '\n          Add to Cart\n        ') : null, this.preorder ? _createElement('a', {
                            'href': '/cart.php?action=add&product_id=' + this.id,
                            'data-event-type': 'product-click',
                            'className': 'button button--small card-figcaption-button theme-btn-primary',
                            'key': '5040'
                        }, '\n          Pre-Order\n        ') : null))));
                    }, { count: undefined })];
            }
            function repeatItems2(items, itemsIndex) {
                return [items(function () {
                        function onClick1() {
                            Convermax.handleProductImageSlider(this.id, 'first');
                        }
                        function onClick2() {
                            Convermax.handleProductImageSlider(this.id, 'second');
                        }
                        function mergeProps(inline, external) {
                            var res = Object.assign({}, inline, external);
                            if (inline.hasOwnProperty('style')) {
                                res.style = _.defaults(res.style, inline.style);
                            }
                            if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                res.className = external.className + ' ' + inline.className;
                            }
                            return res;
                        }
                        function repeatI4(i, iIndex) {
                            return _createElement('span', {
                                'key': i,
                                'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                            });
                        }
                        return _createElement('li', { 'className': 'product' }, _createElement('article', {
                            'data-comp': this.id,
                            'className': 'listItem',
                            'data-product-id': this.id
                        }, _createElement('figure', { 'className': 'listItem-figure figure-content' }, this.retail_price > this.price ? _createElement('div', {
                            'className': 'sale-flag-sash',
                            'key': '158'
                        }, _createElement('span', { 'className': 'sale-text' }, 'Sale')) : null, _createElement('a', {
                            'href': this.custom_url,
                            'className': 'card-image-link--slider'
                        }, _createElement('div', { 'className': 'card-img-container card-img-container--slider' }, _createElement('img', {
                            'className': 'card-image first is-active',
                            'data-sizes': 'auto',
                            'src': this.imageOrDefault(this.images_image_thumbnail1),
                            'alt': this.removeHTML(this.name),
                            'title': this.removeHTML(this.name),
                            'onError': e => this.onImageError(e, 'images_image_thumbnail1'),
                            'sizes': '240px'
                        }), this.images_image_thumbnail2 ? _createElement('img', {
                            'className': 'card-image second lazyautosizes ls-is-cached lazyloaded',
                            'data-sizes': 'auto',
                            'src': this.imageOrDefault(this.images_image_thumbnail2),
                            'alt': this.removeHTML(this.name),
                            'title': this.removeHTML(this.name),
                            'onError': e => this.onImageError(e, 'images_image_thumbnail2'),
                            'sizes': '240px',
                            'key': '723'
                        }) : null, this.images_image_thumbnail2 ? _createElement('div', {
                            'className': 'card-arrows',
                            'key': '1081'
                        }, _createElement('span', {
                            'onClick': onClick1.bind(this),
                            'className': 'card-image-prev card-image-arrow'
                        }, _createElement('svg', { 'className': 'theme-icon' }, _createElement('use', { 'xlinkHref': '#icon-chevron-left' }))), _createElement('span', {
                            'onClick': onClick2.bind(this),
                            'className': 'card-image-next card-image-arrow'
                        }, _createElement('svg', { 'className': 'theme-icon' }, _createElement('use', { 'xlinkHref': '#icon-chevron-right' })))) : null))), _createElement('div', { 'className': 'listItem-body' }, _createElement('div', { 'className': 'listItem-content' }, _createElement('div', { 'className': 'listItem-details card-swatch-body' }, _createElement('h4', { 'className': 'listItem-title' }, _createElement('a', Object.assign({}, { 'href': this.custom_url }, { dangerouslySetInnerHTML: { __html: this.name } }))), _createElement('p', mergeProps({ 'className': 'listItem-description' }, { dangerouslySetInnerHTML: { __html: this.Short_Description } }))), _createElement('div', { 'className': 'listItem-actions' }, _createElement('p', {
                            'className': 'card-text product-rating',
                            'data-test-info-type': 'productRating'
                        }, [_createElement.apply(this, [
                                'div',
                                {
                                    'className': 'cm_review-stars',
                                    'key': '23000'
                                },
                                _map([
                                    0,
                                    1,
                                    2,
                                    3,
                                    4
                                ], repeatI4.bind(this))
                            ])]), _createElement('div', { 'className': 'listItem-price' }, this.retail_price > this.price ? _createElement('div', {
                            'className': 'price-section price-section--withoutTax rrp-price--withoutTax',
                            'key': '2676'
                        }, _createElement('span', { 'className': 'price price--rrp' }, this.formatPrice(this.retail_price))) : null, _createElement('div', { 'className': 'price-section price-section--withoutTax actual-price' }, _createElement('span', { 'className': 'price price--withoutTax' }, this.formatPrice(this.price)))), _createElement('div', { 'className': 'listItem-actions-button-section' }, !this.out_of_stock && !this.preorder ? _createElement('a', {
                            'href': '/cart.php?action=add&product_id=' + this.id,
                            'data-event-type': 'product-click',
                            'className': 'button button--small theme-btn-primary card-figcaption-button',
                            'key': '3176'
                        }, _createElement('span', {
                            'className': 'icon-add-to-cart show theme-icon',
                            'data-icon': '\uE900'
                        }), _createElement('span', { 'className': 'show' }, 'Add to Cart')) : null, this.preorder ? _createElement('a', {
                            'href': '/cart.php?action=add&product_id=' + this.id,
                            'data-event-type': 'product-click',
                            'className': 'button button--small theme-btn-primary card-figcaption-button',
                            'key': '3553'
                        }, _createElement('span', { 'className': 'show' }, 'Pre-Order')) : null), _createElement('div', { 'className': 'listItem-buttons' }, _createElement('a', {
                            'className': 'quickview show',
                            'data-product-id': this.id
                        }), _createElement('a', { 'className': 'wishlist show list_wishlist' }, _createElement('p', {
                            'className': 'wishlist_url',
                            'data-whslst_url': '/wishlist.php?action=add&product_id=' + this.id
                        })), _createElement('label', {
                            'className': 'custom-compare show',
                            'htmlFor': 'compare-' + this.id
                        }, _createElement('input', {
                            'type': 'checkbox',
                            'name': 'products[]',
                            'value': this.id,
                            'id': 'compare-' + this.id,
                            'data-compare-id': this.id
                        }))))))));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_SearchResult' }, this.view === 'grid' ? _createElement.apply(this, [
                'div',
                {
                    'className': 'is-open productGrid cmRepeater_items',
                    'key': '34'
                },
                _map(this.items, repeatItems1.bind(this))
            ]) : null, this.view === 'list' ? _createElement.apply(this, [
                'div',
                {
                    'className': 'is-open productList cmRepeater_items',
                    'key': '9490'
                },
                _map(this.items, repeatItems2.bind(this))
            ]) : null, [this.message(function () {
                    return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired.'), '\n  Product information, including pricing and fitments, is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                            'href': 'mailto:support@convermax.com',
                            'className': 'cm_message-link'
                        }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('AggressiveWheels') ? [_createElement('div', { 'key': '4980' }, '\n  Warning: Aggressive wheel options are displayed, but we cannot guarantee they will fit your selected\n  vehicle.\n')] : null, this.hasMessage('IncompatibleParts') && !this.notFilteredByVehicle ? [_createElement('div', {
                            'className': 'cm_message__incompatible',
                            'key': '7050'
                        }, _createElement('div', {}, '\n    The products shown below will not fit a ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    The products results are based on your keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected && !this.notFilteredByVehicle && !this.hasMessage('NonVehicleProducts') ? [_createElement('div', {
                            'className': 'cm_message__universal',
                            'key': '10350'
                        }, '\n  The system was unable to find any vehicle specific products that fit a\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Click ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'href': this.searchByVehicleUrl
                        }, 'here'), ' to clear your vehicle\n  selection and browse all products in the category.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                        'className': 'cmTemplate_badRequest',
                        'key': '1568'
                    }, 'Your request failed to process (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.startOver
                    }, 'clear your search'), ' and try\nsearching for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                        'className': 'cmTemplate_corrected',
                        'key': '1982'
                    }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any products. The system searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                        'className': 'cmTemplate_networkError',
                        'key': '2236'
                    }, 'Network error\n', this.error ? [
                        '(id: ',
                        this.error.userId,
                        ', timestamp: ',
                        this.error.timestamp,
                        ', instance: ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nThe search server could not be reached. Please try again later.\n') : null, this.template === 'nothing' ? _createElement('div', {
                        'className': 'cmTemplate_nothing',
                        'key': '2554'
                    }, _createElement('div', {}, this.pageType !== 'search' && (!this.isFacetsSelected || this.notFilteredByVehicle) ? [
                        '\n    This ',
                        this.pageType,
                        ' doesn\'t contain any\n    ',
                        !!this.vehicleString && !this.notFilteredByVehicle ? [
                            '\n      products that fit a ',
                            _createElement('span', { 'key': '28171' }, '\'', this.vehicleString, '\'.')
                        ] : null,
                        !this.vehicleString || this.notFilteredByVehicle ? [' products.'] : null
                    ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '30991' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString && !this.notFilteredByVehicle ? [
                        '\n    Click ',
                        _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.startOver,
                            'key': '32301'
                        }, 'here'),
                        ' to clear\n    ',
                        !this.vehicleString ? [' selection. '] : null,
                        this.vehicleString && (!this.isFacetsSelected || !this.notFilteredByVehicle) ? ['\n      all keyword searches, filter selections, and vehicle selections to again browse all products.\n    '] : null
                    ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                        'className': 'cmTemplate_timeout',
                        'key': '3749'
                    }, 'Your search request timed out.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                        'className': 'cmTemplate_partial',
                        'key': '3968'
                    }, 'The system was unable to find products to match your entire search of\n', _createElement('span', { 'className': 'query' }, this.originalQuery), '. A partial search was performed instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                        'className': 'cmTemplate_unknown',
                        'key': '4212'
                    }, 'Your search request failed to process\n', this.error ? [
                        '\n  (',
                        this.error.status ? [
                            _createElement('b', { 'key': '43630' }, 'status:'),
                            ' ',
                            this.error.status,
                            ', '
                        ] : null,
                        this.error.userId ? [
                            _createElement('b', { 'key': '44500' }, 'id:'),
                            ' ',
                            this.error.userId,
                            ', '
                        ] : null,
                        this.error.timestamp ? [
                            _createElement('b', { 'key': '45330' }, 'timestamp:'),
                            ' ',
                            this.error.timestamp,
                            ', '
                        ] : null,
                        _createElement('b', { 'key': '43284' }, 'instance:'),
                        ' ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'results',
            items: undefined
        })], [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'product-listing-filter-section bottom cm_SearchHeader' }, _createElement('div', { 'className': 'cm_SearchHeader__container' }, _createElement('div', { 'className': 'cm_left-block' }, _createElement('div', { 'className': 'cm_total-hits' }, _createElement('div', { 'className': 'product-listing-counter show' }, _createElement('div', { 'className': 'pagination-count' }, this.pageFrom, ' of ', this.pageTo, ' Items'))), [this.pagination(function () {
                    function repeatButtons1(buttons, buttonsIndex) {
                        return [buttons(function () {
                                return this.template === 'prev' ? _createElement('li', { 'className': 'pagination-item pagination-item--previous cmTemplate_prev' }, _createElement('a', {
                                    'href': this.pageLink,
                                    'className': 'pagination-link',
                                    'aria-label': 'link'
                                }, _createElement('i', {
                                    'className': 'icon',
                                    'aria-hidden': 'true'
                                }, _createElement('svg', {}, _createElement('use', {
                                    'xmlnsXlink': 'http://www.w3.org/1999/xlink',
                                    'xlinkHref': '#icon-chevron-left'
                                }))), '\n          Previous\n        ')) : this.template === 'next' ? _createElement('li', { 'className': 'pagination-item pagination-item--next cmTemplate_next' }, _createElement('a', {
                                    'href': this.pageLink,
                                    'className': 'pagination-link',
                                    'aria-label': 'link'
                                }, '\n          Next\n          ', _createElement('i', {
                                    'className': 'icon',
                                    'aria-hidden': 'true'
                                }, _createElement('svg', {}, _createElement('use', {
                                    'xmlnsXlink': 'http://www.w3.org/1999/xlink',
                                    'xlinkHref': '#icon-chevron-right'
                                }))))) : this.template === 'delimiter' ? _createElement('li', { 'className': 'pagination-item cmTemplate_delimiter' }, _createElement('span', { 'className': 'pagination-link' }, '\u2026')) : this.template === 'page' ? _createElement('li', { 'className': 'pagination-item' + (this.isActive ? ' pagination-item--current' : '') + ' cmTemplate_page' }, this.isActive ? _createElement('a', {
                                    'href': this.pageLink,
                                    'className': 'pagination-link',
                                    'key': '122'
                                }, this.page) : null, !this.isActive ? _createElement('a', {
                                    'href': this.pageLink,
                                    'className': 'pagination-link',
                                    'key': '218'
                                }, this.page) : null) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_pagination' }, _createElement('div', { 'className': 'product-listing-pagination show' }, _createElement('nav', { 'className': 'pagination' }, _createElement.apply(this, [
                        'ul',
                        { 'className': 'pagination pagination-list cmRepeater_buttons' },
                        _map(this.buttons, repeatButtons1.bind(this))
                    ]))));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]), _createElement('div', { 'className': 'cm_right-block' }, [this.facetDialogButton(function () {
                    return _createElement('div', { 'className': 'cm_facetDialogButton' }, [this.dialogButton(function () {
                            return _createElement('div', { 'className': 'cm_dialogButton' }, '\n  Filters \n  ', this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon',
                                    'width': '26',
                                    'height': '27',
                                    'viewBox': '0 0 26 27',
                                    'fill': 'none',
                                    'xmlns': 'http://www.w3.org/2000/svg',
                                    'key': '430'
                                }, _createElement('path', {
                                    'd': 'M24.2501 13.2046H9.43388C9.26634 12.7297 8.99412 12.2987 8.63738 11.9433C7.99408 11.3302 7.13952 10.988 6.2508 10.9878C5.36208 10.9876 4.50736 11.3293 3.86378 11.9422C3.23067 12.5754 2.875 13.4341 2.875 14.3295C2.875 15.2249 3.23067 16.0837 3.86378 16.7168C4.07502 16.9278 4.36142 17.0462 4.65998 17.046C4.95854 17.0458 5.24479 16.9271 5.45576 16.7158C5.66674 16.5046 5.78516 16.2182 5.78497 15.9196C5.78478 15.6211 5.666 15.3348 5.45475 15.1238C5.35018 15.0195 5.26723 14.8956 5.21066 14.7592C5.15409 14.6227 5.12502 14.4764 5.12512 14.3287C5.12521 14.181 5.15447 14.0348 5.21121 13.8985C5.26795 13.7621 5.35106 13.6383 5.45576 13.5341C5.66944 13.3286 5.95442 13.2139 6.25086 13.2142C6.5473 13.2144 6.83212 13.3294 7.0455 13.5352C7.14999 13.6394 7.23288 13.7632 7.28945 13.8995C7.34601 14.0358 7.37512 14.182 7.37512 14.3295C7.37512 14.4771 7.34601 14.6232 7.28945 14.7595C7.23288 14.8958 7.14999 15.0196 7.0455 15.1238C6.83568 15.3351 6.7181 15.6209 6.71851 15.9186C6.71891 16.2164 6.83727 16.5018 7.04767 16.7125C7.25808 16.9232 7.54338 17.0419 7.84113 17.0428C8.13888 17.0436 8.42482 16.9264 8.63636 16.7168C8.99325 16.361 9.26581 15.9297 9.43399 15.4546H24.2501C24.5485 15.4546 24.8346 15.3361 25.0456 15.1251C25.2566 14.9141 25.3751 14.6279 25.3751 14.3296C25.3751 14.0312 25.2566 13.7451 25.0456 13.5341C24.8346 13.3231 24.5485 13.2046 24.2501 13.2046Z',
                                    'fill': '#2F2F2F'
                                }), _createElement('path', {
                                    'd': 'M17.3638 20.9423C17.0069 21.2982 16.7343 21.7295 16.5661 22.2046H1.75C1.45163 22.2046 1.16548 22.3232 0.954505 22.5341C0.743527 22.7451 0.625 23.0313 0.625 23.3296C0.625 23.628 0.743527 23.9141 0.954505 24.1251C1.16548 24.3361 1.45163 24.4546 1.75 24.4546H16.5662C16.7344 24.9297 17.007 25.361 17.3639 25.7169C17.5754 25.9264 17.8614 26.0436 18.1591 26.0428C18.4569 26.042 18.7422 25.9233 18.9526 25.7126C19.163 25.5019 19.2813 25.2164 19.2817 24.9187C19.2821 24.6209 19.1646 24.3351 18.9547 24.1239C18.8502 24.0196 18.7672 23.8956 18.7106 23.7592C18.6541 23.6228 18.625 23.4765 18.6251 23.3288C18.6252 23.1811 18.6545 23.0349 18.7112 22.8985C18.7679 22.7621 18.851 22.6383 18.9558 22.5341C19.1694 22.3287 19.4544 22.214 19.7508 22.2142C20.0473 22.2144 20.3321 22.3295 20.5455 22.5353C20.65 22.6395 20.7329 22.7633 20.7894 22.8996C20.846 23.0359 20.8751 23.182 20.8751 23.3296C20.8751 23.4771 20.846 23.6233 20.7894 23.7596C20.7329 23.8959 20.65 24.0197 20.5455 24.1239C20.3357 24.3351 20.2181 24.6209 20.2185 24.9187C20.2189 25.2164 20.3373 25.5019 20.5477 25.7126C20.7581 25.9233 21.0434 26.042 21.3411 26.0428C21.6389 26.0436 21.9248 25.9264 22.1364 25.7169C22.7693 25.0839 23.1249 24.2255 23.1251 23.3304C23.1253 22.4353 22.7701 21.5767 22.1375 20.9434C21.4942 20.3302 20.6396 19.988 19.7508 19.9878C18.8621 19.9876 18.0074 20.3294 17.3638 20.9423Z',
                                    'fill': '#2F2F2F'
                                }), _createElement('path', {
                                    'd': 'M12.5652 4.20459H1.75C1.45163 4.20459 1.16548 4.32312 0.954505 4.53409C0.743527 4.74507 0.625 5.03122 0.625 5.32959C0.625 5.62796 0.743527 5.91411 0.954505 6.12508C1.16548 6.33606 1.45163 6.45459 1.75 6.45459H12.664C12.5696 5.98952 12.5201 5.50818 12.5201 5.01527C12.5201 4.7412 12.5354 4.4707 12.5652 4.20459Z',
                                    'fill': '#2F2F2F'
                                }), _createElement('circle', {
                                    'cx': '19.7219',
                                    'cy': '5.01533',
                                    'r': '4.8',
                                    'fill': '#6AB783'
                                }))] : null, !this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon empty',
                                    'xmlns': 'http://www.w3.org/2000/svg',
                                    'width': '36',
                                    'height': '36',
                                    'viewBox': '0 0 36 36',
                                    'fill': 'none',
                                    'key': '34040'
                                }, _createElement('path', {
                                    'd': 'M6.75 10.4546H21.5662C21.7344 10.9297 22.007 11.3611 22.3639 11.7169C22.5758 11.9246 22.8611 12.0402 23.1579 12.0386C23.4546 12.037 23.7387 11.9183 23.9483 11.7084C24.158 11.4984 24.2763 11.2141 24.2775 10.9174C24.2787 10.6207 24.1626 10.3355 23.9546 10.1239C23.85 10.0196 23.7671 9.89566 23.7105 9.75922C23.654 9.62278 23.6249 9.47651 23.625 9.32881C23.6251 9.18111 23.6543 9.03488 23.7111 8.89851C23.7678 8.76215 23.8509 8.63832 23.9556 8.53415C24.1693 8.32868 24.4543 8.21401 24.7507 8.21422C25.0472 8.21443 25.332 8.3295 25.5454 8.53528C25.6499 8.63949 25.7328 8.76329 25.7893 8.89959C25.8459 9.03589 25.875 9.18201 25.875 9.32958C25.875 9.47715 25.8459 9.62328 25.7893 9.75958C25.7328 9.89588 25.6499 10.0197 25.5454 10.1239C25.3356 10.3351 25.218 10.6209 25.2184 10.9187C25.2188 11.2164 25.3371 11.5019 25.5476 11.7126C25.7579 11.9233 26.0433 12.042 26.341 12.0428C26.6388 12.0436 26.9247 11.9264 27.1362 11.7169C27.7691 11.0839 28.1248 10.2255 28.125 9.33038C28.1252 8.43526 27.77 7.57668 27.1374 6.9434C26.4941 6.33022 25.6395 5.98806 24.7508 5.98785C23.8621 5.98764 23.0073 6.3294 22.3638 6.94228C22.0069 7.29819 21.7344 7.72955 21.5662 8.20464H6.75C6.45163 8.20464 6.16548 8.32317 5.95451 8.53414C5.74353 8.74512 5.625 9.03127 5.625 9.32964C5.625 9.62801 5.74353 9.91416 5.95451 10.1251C6.16548 10.3361 6.45163 10.4546 6.75 10.4546Z',
                                    'fill': '#2F2F2F'
                                }), _createElement('path', {
                                    'd': 'M29.2501 17.2046H14.4339C14.2663 16.7298 13.9941 16.2988 13.6374 15.9434C12.9941 15.3302 12.1395 14.9881 11.2508 14.9879C10.3621 14.9876 9.50736 15.3294 8.86378 15.9423C8.23067 16.5755 7.875 17.4342 7.875 18.3296C7.875 19.225 8.23067 20.0837 8.86378 20.7169C9.07502 20.9279 9.36142 21.0463 9.65998 21.0461C9.95854 21.0459 10.2448 20.9271 10.4558 20.7159C10.6667 20.5046 10.7852 20.2182 10.785 19.9197C10.7848 19.6211 10.666 19.3349 10.4548 19.1239C10.3502 19.0196 10.2672 18.8957 10.2107 18.7592C10.1541 18.6228 10.125 18.4765 10.1251 18.3288C10.1252 18.1811 10.1545 18.0349 10.2112 17.8985C10.2679 17.7621 10.3511 17.6383 10.4558 17.5342C10.6694 17.3287 10.9544 17.214 11.2509 17.2142C11.5473 17.2144 11.8321 17.3295 12.0455 17.5353C12.15 17.6395 12.2329 17.7633 12.2894 17.8996C12.346 18.0359 12.3751 18.182 12.3751 18.3296C12.3751 18.4772 12.346 18.6233 12.2894 18.7596C12.2329 18.8959 12.15 19.0197 12.0455 19.1239C11.8357 19.3351 11.7181 19.6209 11.7185 19.9187C11.7189 20.2164 11.8373 20.5019 12.0477 20.7126C12.2581 20.9233 12.5434 21.042 12.8411 21.0428C13.1389 21.0436 13.4248 20.9264 13.6364 20.7169C13.9932 20.3611 14.2658 19.9297 14.434 19.4546H29.2501C29.5485 19.4546 29.8346 19.3361 30.0456 19.1251C30.2566 18.9142 30.3751 18.628 30.3751 18.3296C30.3751 18.0313 30.2566 17.7451 30.0456 17.5341C29.8346 17.3232 29.5485 17.2046 29.2501 17.2046Z',
                                    'fill': '#2F2F2F'
                                }), _createElement('path', {
                                    'd': 'M22.3638 24.9423C22.0069 25.2982 21.7343 25.7295 21.5661 26.2046H6.75C6.45163 26.2046 6.16548 26.3232 5.95451 26.5341C5.74353 26.7451 5.625 27.0313 5.625 27.3296C5.625 27.628 5.74353 27.9141 5.95451 28.1251C6.16548 28.3361 6.45163 28.4546 6.75 28.4546H21.5662C21.7344 28.9297 22.007 29.361 22.3639 29.7169C22.5754 29.9264 22.8614 30.0436 23.1591 30.0428C23.4569 30.042 23.7422 29.9233 23.9526 29.7126C24.163 29.5019 24.2813 29.2164 24.2817 28.9187C24.2821 28.6209 24.1646 28.3351 23.9547 28.1239C23.8502 28.0196 23.7672 27.8956 23.7106 27.7592C23.6541 27.6228 23.625 27.4765 23.6251 27.3288C23.6252 27.1811 23.6545 27.0349 23.7112 26.8985C23.7679 26.7621 23.851 26.6383 23.9558 26.5341C24.1694 26.3287 24.4544 26.214 24.7508 26.2142C25.0473 26.2144 25.3321 26.3295 25.5455 26.5353C25.65 26.6395 25.7329 26.7633 25.7894 26.8996C25.846 27.0359 25.8751 27.182 25.8751 27.3296C25.8751 27.4771 25.846 27.6233 25.7894 27.7596C25.7329 27.8959 25.65 28.0197 25.5455 28.1239C25.3357 28.3351 25.2181 28.6209 25.2185 28.9187C25.2189 29.2164 25.3373 29.5019 25.5477 29.7126C25.7581 29.9233 26.0434 30.042 26.3411 30.0428C26.6389 30.0436 26.9248 29.9264 27.1364 29.7169C27.7693 29.0839 28.1249 28.2255 28.1251 27.3304C28.1253 26.4353 27.7701 25.5767 27.1375 24.9434C26.4942 24.3302 25.6396 23.988 24.7508 23.9878C23.8621 23.9876 23.0074 24.3294 22.3638 24.9423Z',
                                    'fill': '#2F2F2F'
                                }))] : null);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('div', { 'className': 'product-listing-filter-right' }, _createElement('div', { 'className': 'product-view-button show ' + this.view }, _createElement('a', {
                'onClick': this.setView('grid'),
                'className': 'product-view-btn grid-view view-button-grid show',
                'data-view': 'product-grid-view'
            }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-grid-view' }))), _createElement('a', {
                'onClick': this.setView('list'),
                'className': 'product-view-btn list-view view-button-list show',
                'data-view': 'product-list-view'
            }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-list-view' }))))), _createElement('div', { 'className': 'cm_sort sort-by' }, _createElement('span', { 'className': 'sort-label' }, 'Sort by: '), '\n      ', this.sortSelect, '\n    '))));
        }, {
            widgetName: 'search-header',
            items: undefined
        })])));
}
        export const componentNames = ["cm:filterChips","cm:filterInput","cm:filterInput","cm:select","cm:inputMin","cm:inputMax","cm:Inputs","cm:inputMin","cm:inputMax","cm:Inputs","cm:facets","cm:FacetPanel","cm:message","cm:customMessage","cm:filterInput","cm:facetToggle","cm:SearchVehicleWidget","cm:facets","cm:VehicleSpeakerFacetPanel","cm:pagination","cm:dialogButton","cm:facetDialogButton","cm:SearchHeader","cm:message","cm:SearchResult","cm:pagination","cm:dialogButton","cm:facetDialogButton","cm:SearchHeader"]